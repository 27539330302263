<template>
	<div class="page-status-query-progress">
		<div class="custom-headline">入驻申请</div>
		<el-card>
			<Step v-model="stepActiveIndex"></Step>
			<div class="custom-card">
				<div class="custom-title">入驻进度查询</div>
				<el-form class="query-progress-form" ref="queryProgressForm" :model="queryProgressForm"
					:rules="queryProgressFormRules" label-width="220px">
					<el-row>
						<el-col :span="14">
							<el-form-item label="入驻手机号" prop="mobile">
								<el-input v-model.trim="queryProgressForm.mobile" placeholder="请输入入驻手机号" maxlength="11">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<el-form-item label="验证码" prop="authCode">
								<el-input v-model.trim="queryProgressForm.authCode" placeholder="请输入验证码" maxlength="6">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-button v-if="sendCodeMessageFlag" class="custom-send-msg-btn" size="small">
								{{remainTime+'s'}}
							</el-button>
							<el-button v-else class="custom-send-msg-btn" size="small" @click="sendCodeMessage">
								{{sendCodeMessageErrorFlag ? '重新发送' : '获取验证码'}}</el-button>
						</el-col>
					</el-row>
				</el-form>
				<div class="custom-action-box">
					<el-button class="btn" type="primary" :loading="submitPendingFlag" @click="getJoinProgressStatus">查询
					</el-button>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import Step from "../components/Step";
import { sendCodeMessage, getJoinProgressStatus } from "@/api/api-join-mall";
export default {
	components: {
		Step,
	},
	data() {
		return {
			stepActiveIndex: [1, 3],
			// 表单
			queryProgressForm: {
				mobile: "",
				authCode: "",
			},
			queryProgressFormRules: {
				mobile: [
					{
						required: true,
						message: "请输入手机号",
						trigger: "blur",
					},
					{ max: 11, message: "最多输入11个字符", trigger: "blur" },
					{
						pattern:
							/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
						message: "请输入有效的手机号",
						trigger: ["blur"],
					},
				],
				authCode: [
					{
						required: true,
						message: "请输入验证码",
						trigger: "blur",
					},
					{ max: 6, message: "最多输入6个字符", trigger: "blur" },
					{
						pattern: /^\d{6}$/,
						message: "请输入6位数的验证码",
						trigger: ["blur"],
					},
				],
			},
			// 验证码 - 倒计时开关
			sendCodeMessageFlag: false,
			// 验证码 - 防连点
			sendCodeMessageForbidFlag: false,
			// 验证码 - 按钮文字切换
			sendCodeMessageErrorFlag: false,
			remainTime: 59,
			timer: null,
			// 操作
			submitPendingFlag: false,
		};
	},
	methods: {
		/**
		 * 获取验证码
		 */
		_clearTimer() {
			clearInterval(this.timer);
			this.sendCodeMessageFlag = false;
		},
		sendCodeMessage() {
			if (this.sendCodeMessageFlag || this.sendCodeMessageForbidFlag)
				return;
			this.$refs.queryProgressForm.validateField("mobile", (valid) => {
				if (!valid) {
					this.sendCodeMessageForbidFlag = true;
					sendCodeMessage(this.queryProgressForm)
						.then((res) => {
							if (res.data.code === 0) {
								this.remainTime = 59;
								this.sendCodeMessageFlag = true;
								this.timer = setInterval(() => {
									this.remainTime--;
									if (this.remainTime < 1) this._clearTimer();
								}, 1000);
								this.sendCodeMessageErrorFlag = false;
							} else {
								this.sendCodeMessageErrorFlag = true;
							}
							this.sendCodeMessageForbidFlag = false;
						})
						.catch(() => {
							this.sendCodeMessageErrorFlag = true;
							this.sendCodeMessageForbidFlag = false;
						});
				}
			});
		},
		/**
		 * 查询入驻进度
		 */
		getJoinProgressStatus() {
			this.$refs.queryProgressForm.validate((valid) => {
				if (valid) {
					this.submitPendingFlag = true;
					getJoinProgressStatus(this.queryProgressForm).then(
						(res) => {
							if (res.data.code === 0) {
								this.$router.push({
									name: "StatusProgressing",
									query: {
										status: res.data.data
									}
								});
							} else {
								this.$message.warning(res.data.message);
							}
							this.submitPendingFlag = false;
						}
					).catch(() => {
						this.submitPendingFlag = false;
					});
				}
			});
		},
	},
	beforeDestroy() {
		this._clearTimer();
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
.page-status-query-progress {
	.query-progress-form {
		margin-top: 50px;
		margin-left: 180px;
	}
	.submit-btn {
		font-weight: 400;
		width: 130px;
		margin-top: 80px;
	}
}
</style>