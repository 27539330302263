<template>
  <div class="step-box">
    <div class="join-step cf">
      <div class="step-item fl" v-for="(j, index) in joinSteps" :key="j.id" :class="{'active': index == value[0]}">
        <div class="inline index">{{j.id}}</div>
        <div class="inline label">{{j.label}}</div>
        <div class="inline sub">{{j.sub}}</div>
        <i class="inline icon el-icon-arrow-right" v-if="j.id < 3"></i>
      </div>
    </div>
    <div class="form-step cf">
      <div class="step-item fl" v-for="(f, index) in formSteps" :key="index" :class="{'active': index == value[1]}">
        <div class="line"></div>
        <div class="sign"></div>
        <div class="label">{{f}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
		value: {
			type: Array,
			default: () => {
				return [0, 0]
			}
		}
	},
  data() {
    return {
      joinSteps: [
        {
          id: 1,
          label: '商家填写信息',
          sub: '约24小时'
        },
        {
          id: 2,
          label: '国美家审核',
          sub: '约48小时'
        },
        {
          id: 3,
          label: '入驻成功',
          sub: ''
        }
      ],
      formSteps: ['公司联系人', '企业信息', '品牌资质', '提交审核']
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/base';
.step-box {
  width: 100%;
  color: #CCCCCC;
  .join-step {
    background-color: #FCFCFC;
    padding: 40px 100px;
    .step-item {
      position: relative;
      width: 33.33%;
      text-align: center;
      .index {
        font-size: 18px;
        font-weight: 500;
        width: 26px;
        height: 26px;
        line-height: 26px;
        border-radius: 50%;
        border: 3px solid #CCCCCC;
      }
      .label {
        font-size: 16px;
        margin-left: 10px;
        margin-right: 5px;
      }
      .sub {
        font-size: 10px;
      }
      .icon {
        position: absolute;
        right: 0;
        top: 10px;
        vertical-align: middle;
      }
      &.active {
        color: $color-theme;
        .index {
          border-color: $color-theme;
        }
      }
    }
  }
  .form-step {
    font-size: 12px;
    padding: 20px 160px;
    .step-item {
      position: relative;
      width: calc(100% / 4);
      text-align: center;
      .sign {
        position: relative;
        margin: auto;
        width: 6px;
        height: 6px;
        border: 3px solid #CCCCCC;
        border-radius: 50%;
        margin-bottom: 10px;
        background-color: #fff;
        z-index: 9;
      }
      .line {
        position: absolute;
        top: 5.5px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #CCCCCC;
        z-index: 1;
      }
      .label {
        font-size: 12px;
      }
      &:first-of-type {
        .line {
          left: 50%;
        }
      }
      &:last-of-type {
        .line {
          right: 50%;
        }
      }
      &.active {
        color: #4A4A4A;
        .sign {
          background-color: $color-theme;
          border-color: $color-theme;
        }
      }
    }
  }
}
</style>